<template>
  <b-row align-v="center" class="vh-100 p-4" align-h="center">
    <b-col cols="12" md="10">
      <validation-observer ref="formRef">
        <b-form @submit.prevent>
          <form-wizard
            :title="$t('pages.companyRegister.title')"
            :subtitle="null"
            class="steps-transparent mb-3"
            color="#0071F2"
          >
            <tab-content :title="$t('pages.companyRegister.step1')">
              <validation-observer ref="tab1">
                <b-row align-h="center">
                  <b-col cols="12" md="7">
                    <b-form-group
                      :label="$t('pages.companyRegister.singular.name')"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="organizationName"
                        rules="required|min:5"
                      >
                        <b-form-input
                          id="name"
                          ref="firstFocusRef"
                          v-model="form.name"
                          :state="errors.length > 0 ? false : null"
                          placeholder="PT ODISYS INDONESIA"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="7">
                    <b-form-group
                      :label="$t('pages.companyRegister.singular.brand')"
                      label-for="brand"
                    >
                      <b-form-input
                        id="brand"
                        v-model="form.brand"
                        placeholder="Uplink"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="7">
                    <b-form-group
                      :label="$t('pages.companyRegister.singular.businessType')"
                      label-for="business-type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="businessType"
                        rules="required"
                      >
                        <v-select
                          v-model="form.businessType"
                          id="business-type"
                          label="name"
                          :options="businessTypes"
                          :reduce="field => field.value"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <tab-content :title="$t('pages.companyRegister.step2')">
              <validation-observer ref="tab2">
                <b-row align-h="center">
                  <b-col cols="12" md="7">
                    <b-form-group
                      :label="$t('pages.companyRegister.singular.cutOffDate')"
                      label-for="cut-off-date"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="cutOffDate"
                        rules="required"
                      >
                        <flat-pickr
                          id="cut-off-date"
                          :config="dateFormat"
                          v-model="form.cutOffDate"
                          class="form-control"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-alert variant="info" show>
                      <h4 class="alert-heading">
                        <feather-icon icon="InfoIcon" />
                        Info
                      </h4>
                      <div class="alert-body">
                        <ul class="pl-2 mb-0">
                          <li>
                            <span>
                              <span class="font-weight-bolder font-italic"
                                >Lorem ipsum</span
                              >
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit, sed do eiusmod tempor incididunt ut labore
                              et dolore magna aliqua. Ut enim ad minim veniam,
                              quis nostrud exercitation ullamco laboris nisi ut
                              aliquip ex ea commodo consequat.
                            </span>
                          </li>
                        </ul>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </validation-observer>
            </tab-content>

            <tab-content :title="$t('pages.companyRegister.step3')">
              <div class="text-center">
                <div
                  class="d-flex align-items-center justify-content-center mb-5 pb-50"
                >
                  <h6 class="mr-1 mb-0">
                    {{ $t("pages.companyRegister.monthly") }}
                  </h6>
                  <b-form-checkbox
                    id="priceSwitch"
                    v-model="status"
                    name="price-switch"
                    value="annually"
                    unchecked-value="monthly"
                    switch
                    @input="togglePlan"
                  />
                  <h6 class="ml-50 mb-0">
                    {{ $t("pages.companyRegister.annually") }}
                  </h6>
                </div>
              </div>
              <b-row class="pricing-card" align-h="center">
                <b-col sm="10" md="12" lg="10">
                  <b-row align-h="center">
                    <b-col
                      md="4"
                      :key="`wrapper-${index}`"
                      v-for="(plan, index) in pricing"
                    >
                      <b-card
                        :class="[plan.popular ? 'popular' : '']"
                        align="center"
                      >
                        <div
                          v-show="plan.popular"
                          class="pricing-badge text-right"
                        >
                          <b-badge variant="light-primary" pill>
                            Popular
                          </b-badge>
                        </div>
                        <!-- img -->
                        <b-img
                          v-if="plan.img"
                          :src="plan.img"
                          :class="[
                            index === 0
                              ? 'mb-2 mt-5'
                              : index === 1
                              ? 'mb-1'
                              : 'mb-2'
                          ]"
                          alt="basic svg img"
                        />
                        <!--/ img -->
                        <h3>{{ plan.title }}</h3>
                        <b-card-text>
                          {{ plan.subtitle }}
                        </b-card-text>

                        <!-- annual plan -->
                        <div class="annual-plan">
                          <div class="plan-price mt-2">
                            <sup
                              v-if="plan.monthlyPrice"
                              class="font-medium-1 font-weight-bold text-primary"
                              >IDR</sup
                            >
                            <span
                              v-if="plan.monthlyPrice"
                              class="pricing-basic-value font-weight-bolder text-primary"
                              >{{
                                monthlyPlanShow
                                  ? plan.monthlyPrice
                                  : plan.yearlyPlan.perMonth
                              }}</span
                            >
                            <span
                              v-else
                              class="pricing-basic-value font-weight-bolder text-primary"
                              >FREE</span
                            >
                            <sub
                              v-if="plan.monthlyPrice"
                              class="font-medium-1 font-weight-bolder text-primary"
                              >K&nbsp;</sub
                            >
                            <sub
                              v-if="plan.monthlyPrice"
                              class="pricing-duration text-body font-medium-1 font-weight-bold"
                              >/month</sub
                            >
                          </div>
                          <small
                            v-show="!monthlyPlanShow"
                            v-if="plan.monthlyPrice"
                            class="annual-pricing text-muted"
                            >IDR {{ plan.yearlyPlan.totalAnual }} / year</small
                          >
                        </div>
                        <!--/ annual plan -->

                        <!-- plan benefit -->
                        <p
                          class="mt-2 mb-0 font-medium-0 font-weight-bolder text-primary text-left"
                        >
                          {{ plan.planHierarcy }}
                        </p>
                        <b-list-group class="list-group-circle text-left">
                          <b-list-group-item
                            v-for="(data, index) in plan.planBenefits"
                            :key="index"
                          >
                            {{ data }}
                          </b-list-group-item>
                        </b-list-group>
                        <!--/ plan benefit -->

                        <!-- buttons -->
                        <b-button
                          block
                          class="mt-2"
                          :disabled="isLoading"
                          :variant="
                            plan.popular ? 'primary' : 'outline-primary'
                          "
                          @click="submitCompany(index + 1)"
                        >
                          Start Trial
                        </b-button>
                      </b-card>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </tab-content>

            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <b-button
                  v-if="props.activeTabIndex > 0"
                  @click="props.prevTab()"
                  variant="outline-primary"
                  >Previous</b-button
                >
              </div>
              <div class="wizard-footer-right">
                <b-button
                  type="submit"
                  v-if="!props.isLastStep"
                  @click="validate(props)"
                  class="wizard-footer-right"
                  variant="primary"
                  >Next</b-button
                >
              </div>
            </template>
          </form-wizard>
        </b-form>
      </validation-observer>
    </b-col>
  </b-row>
</template>

<script>
import { onMounted, ref, nextTick } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, min } from '@validations'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'

import store from '@/store'
import router from '@/router'
import useHttp from '@/comp-functions/useHttp'

import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BCard,
  BImg,
  BCardText,
  BListGroup,
  BListGroupItem,
  BButton,
  BBadge,
  BFormCheckbox,
  BAlert
} from 'bootstrap-vue'

export default {
  components: {
    vSelect,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BCard,
    BImg,
    BCardText,
    BListGroup,
    BListGroupItem,
    BButton,
    BBadge,
    BFormCheckbox,
    BAlert,
    ValidationProvider,
    ValidationObserver,
    flatPickr
  },
  setup () {
    const { $post } = useHttp()

    const formRef = ref(null)
    const tab1 = ref(null)
    const tab2 = ref(null)
    const firstFocusRef = ref(null)
    const isLoading = ref(false)
    const status = ref('monthly')
    const monthlyPlanShow = ref(true)
    const dateFormat = ref({ dateFormat: 'd/m/Y', allowInput: true })
    const togglePlan = () => {
      monthlyPlanShow.value = status.value === 'monthly'
    }

    const form = ref({
      name: '',
      brand: '',
      businessType: '',
      planId: '',
      cutOffDate: ''
    })

    const businessTypes = ref([
      {
        value: 1,
        name: 'Service'
      },
      {
        value: 2,
        name: 'Shipment'
      },
      {
        value: 3,
        name: 'Manufacture'
      }
    ])

    const pricing = ref([
      {
        title: 'Basic',
        img: require('@/assets/images/illustration/Pot1.svg'),
        subtitle: 'A simple start for everyone',
        monthlyPrice: 0,
        yearlyPlan: {
          perMonth: 0,
          totalAnual: 0
        },
        planHierarcy: 'You will get :',
        planBenefits: [
          'Journal Transaction',
          'Cashbank Transaction',
          'Financial Report'
        ],
        popular: false
      },
      {
        title: 'Advance',
        img: require('@/assets/images/illustration/Pot2.svg'),
        subtitle: 'Small to medium businesses',
        monthlyPrice: 259,
        yearlyPlan: {
          perMonth: 249,
          totalAnual: '2,988'
        },
        planHierarcy: 'Everything in Basic, plus :',
        planBenefits: ['Expenses', 'Budgeting', 'Sales', 'Purchase'],
        popular: true
      },
      {
        title: 'Enterprise',
        img: require('@/assets/images/illustration/Pot3.svg'),
        subtitle: 'Solution for big organizations',
        monthlyPrice: 949,
        yearlyPlan: {
          perMonth: 899,
          totalAnual: '10,788'
        },
        planHierarcy: 'Everything in Advance, plus :',
        planBenefits: [
          'Assets Management',
          'Inventory Management',
          'API Integration',
          'Custom Domain App',
          'Priority Customer Services'
        ],
        popular: false
      }
    ])

    const validate = async wizardProps => {
      if (wizardProps.activeTabIndex > 0) {
        const validated = await tab2.value.validate()
        if (validated) {
          wizardProps.nextTab()
        }
      } else {
        const validated = await tab1.value.validate()
        if (validated) {
          wizardProps.nextTab()
        }
      }
    }

    const submitCompany = async planId => {
      form.value.planId = planId
      isLoading.value = true
      try {
        const data = await $post({
          url: 'company',
          data: form.value
        })
        isLoading.value = false
        await store.dispatch('user/setUserData', data)
        router.replace({ name: 'dashboard-analytics' })
      } catch (e) {
        isLoading.value = false
      }
    }

    onMounted(() => {
      nextTick(() => {
        if (firstFocusRef.value) firstFocusRef.value.focus()
      })
    })

    return {
      required,
      min,
      formRef,
      tab1,
      tab2,
      firstFocusRef,
      form,
      isLoading,
      businessTypes,
      validate,
      status,
      monthlyPlanShow,
      togglePlan,
      submitCompany,
      pricing,
      dateFormat
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
